<template>
  <div class="planbox" v-runshow>
    <div class="flex a_i_c title"><span class="tips"></span> 员工培养计划</div>
    <div class="desc">企业持续发展的过程，就是人才持续引进并持续培养的过程</div>
    <div class="flex a_i_s j_c_b flex_box pc_">
      <div class="item_" v-runcentershow>
        <div class="number">01</div>
        <div class="ctx">
          <div class="ctx_title">“新兵连”计划</div>
          <div class="line"></div>
          <p class="ctx_desc">
            新员工入职后，将会安排一到二个月的集中培训，从企业文化、公司忠诚度到产品业务知识，从行业知识到销售技能等，进行全方位的培训和考试，让其快速成为行业里的行家里手。
          </p>
        </div>
      </div>
      <div class="item_" v-runcentershow>
        <div class="number">02</div>
        <div class="ctx">
          <div class="ctx_title">“英雄铸造”计划</div>
          <div class="line"></div>
          <p class="ctx_desc">
            公司将通过体验式培训、模拟、实践等方式对具有非凡潜力的员工展开系统性的培养计划，让其核心竞争力不断提升，公司忠诚度更高，快速成为公司，乃至行业的英雄人物。
          </p>
        </div>
      </div>
      <div class="item_" v-runcentershow>
        <div class="number">03</div>
        <div class="ctx">
          <div class="ctx_title">知识共享平台</div>
          <div class="line"></div>
          <p class="ctx_desc">
            我们拥有完善的内部知识库，积累了大量精选的国家政策文件、行业分析报告、竞友分析材料、业务知识、营销技能工具、产品和商业规划和技术研发等资料，让热爱学习的你获得快速成长。
          </p>
        </div>
      </div>
      <div class="item_" v-runcentershow>
        <div class="number">04</div>
        <div class="ctx">
          <div class="ctx_title">外部师资力量</div>
          <div class="line"></div>
          <p class="ctx_desc">
            我们采用常态化和不定期方式，引进行业内资深专业人士对公司各个部门骨干展开定向的培训。
          </p>
        </div>
      </div>
    </div>

    <div>
      <!-- mobile -->
      <div class="mobile_">
        <div class="block">
          <div class="isswiper" v-runcentershow style="">
            <div class="swiper-wrapper" style="height: 100%">
              <div class="swiper-slide">
                <div class="item_">
                  <div class="number">01</div>
                  <div class="ctx">
                    <div class="ctx_title">“新兵连”计划</div>
                    <div class="line"></div>
                    <p class="ctx_desc">
                      新员工入职后，将会安排一到二个月的集中培训，从企业文化、公司忠诚度到产品业务知识，从行业知识到销售技能等，进行全方位的培训和考试，让其快速成为行业里的行家里手。
                    </p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="item_">
                  <div class="number">02</div>
                  <div class="ctx">
                    <div class="ctx_title">“英雄铸造”计划</div>
                    <div class="line"></div>
                    <p class="ctx_desc">
                      公司将通过体验式培训、模拟、实践等方式对具有非凡潜力的员工展开系统性的培养计划，让其核心竞争力不断提升，公司忠诚度更高，快速成为公司，乃至行业的英雄人物。
                    </p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="item_">
                  <div class="number">03</div>
                  <div class="ctx">
                    <div class="ctx_title">知识共享平台</div>
                    <div class="line"></div>
                    <p class="ctx_desc">
                      我们拥有完善的内部知识库，积累了大量精选的国家政策文件、行业分析报告、竞友分析材料、业务知识、营销技能工具、产品和商业规划和技术研发等资料，让热爱学习的你获得快速成长。
                    </p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="item_">
                  <div class="number">04</div>
                  <div class="ctx">
                    <div class="ctx_title">外部师资力量</div>
                    <div class="line"></div>
                    <p class="ctx_desc">
                      我们采用常态化和不定期方式，引进行业内资深专业人士对公司各个部门骨干展开定向的培训。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="isswiper-pagination swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    var mySwiper = new Swiper(".isswiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".isswiper-pagination",
      },
    });
  },
};
</script>
<style scoped>
.isswiper {
  position: relative;
}
.mobile_ {
  overflow: hidden;
  height: 0;
}
.mobile_ .item_ {
  margin: 0;
}
.mobile_ .item_ {
  width: 5.7rem;
}
.mobile_ .ctx {
  width: 5.7rem;
  height: 6rem;
}
.mobile_ .number {
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 0.6rem;
  margin-top: -0.9rem;
  border-color: #1aa858;
}
.mobile_ .ctx_title {
  font-size: 0.4rem;
  margin-top: 1.4rem;
}
.mobile_ .ctx_desc {
  font-size: 0.28rem;
  line-height: 0.5rem;
}
.isswiper {
  height: 8rem;
}
.swiper-slide {
  display: flex;
  justify-content: center;
}
.ctx {
  background: #fff;
  color: #000;
  font-size: 12px;
  line-height: 200%;
  box-sizing: border-box;
  padding: 0 0.4rem;
  padding-bottom: 30px;
  height: calc((100vw - 320px - 32px) / 4 * 1.45);
  overflow: hidden;
  transition: all 0.4s;
  cursor: pointer;
  width: 3.73rem;
  height: 4.3rem;
}
.line {
  width: 50px;
  height: 2px;
  background: #1aa858;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 0.32rem;
}
.item_ {
  padding-top: 50px;
  position: relative;
  margin: 20px 0.08rem;
  margin-bottom: 40px;
  width: 3.7rem;
}
.ctx_title {
  text-align: center;
  transition: none;
  font-size: 16px;
  margin-top: 0.96rem;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 29px;
  line-height: 41px;
  font-size: 0.29rem;
  line-height: 0.41rem;
}
.number {
  margin-top: -0.54rem;
  position: absolute;
  left: 1.37rem;
  border-radius: 50%;
  border: 1px solid #fff;
  color: #1aa858;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.08rem;
  height: 1.08rem;
  font-size: 26px;
  font-weight: 800;
  transition: all 0.4s;
  font-size: 0.48rem;
}
.planbox {
  background: #f7f7fa;
  padding: 1.2rem 1.9rem;
  /* padding: 80px 160px!important; */
  box-sizing: border-box;
  width: 100vw;
  margin-left: -1.9rem;
}
.title {
  font-size: 20px;
  font-weight: 800;
  color: #000;
  line-height: 200%;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;

  /* margin-top: 40px; */

  font-size: 0.36rem;
  line-height: 0.5rem;
}
.tips {
  width: 0.03rem;
  height: 0.38rem;
  background: #1aa858;
  margin-right: 6px;
}

.desc {
  font-size: 14px;
  color: #61646b;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 40px;
  margin-top: 10px;

  font-size: 0.26rem;
  line-height: 0.36rem;
}
.item_:hover .ctx {
  background: #1aa858;
  color: #fff;
}
.item_:hover .number {
  border-color: #1aa858;
}
.ctx_desc {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #61646b;
  transition: all 0.4s ease;
  font-size: 0.18rem;
  line-height: 0.3rem;
}
.item_:hover .ctx_desc {
  color: #fff;
}
.item_:hover .line {
  background: #fff;
}

@media screen and (max-width: 1024px) {
  .mobile_ {
    height: auto;
    overflow: visible;
  }
  .pc_ {
    display: none;
  }
  .planbox {
    padding: 0.8rem 20px;
    width: 100vw;
    margin-left: -20px;
  }
  .planbox .title {
    justify-content: center;
    position: relative;
    margin-bottom: 0.63rem;
  }
  .planbox span {
    transform: rotate(90deg);
    transform-origin: center;
    position: absolute;
    bottom: -0.58rem;
    margin-left: 10px !important;
  }
  .desc {
    font-size: 0.28rem;
    text-align: center;
    width: 70%;
    margin-left: 15%;
  }

  /* .ctx{
        height: calc((100vw - 320px - 32px)/4*1.15);
    } */
}
/* @media screen and (max-width:1910px) {
    .item_{
        width: calc((100vw - 160px)/4 - 16px)!important;
    }
} */
</style>
