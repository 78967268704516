<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：销售经理</p>
      <p class="part_title">一、岗位职责</p>
      <p class="title">在这里，你可以：</p>
      <div class="detail_desc">
        <p>1、利用具有竞争力的产品和商业模式为客户创造价值；</p>
        <p>2、利用公司搭建的平台积累优质人脉资源；</p>
        <p>3、成为交易和谈判专家，沟通各种交易模式和商务方案，掌握谈判节奏。</p>
      </div>
      <p class="title">主要负责：</p>
      <div class="detail_desc">
        <p>1、完成量化的各项销售指标；</p>
        <p>2、对接医疗健康行业相关客户，树立公司品牌和提升品牌辐射能力；</p>
        <p>
          3、根据市场信息、趋势和客户反馈，密切与研发部门合作，保持高度的市场敏锐度。
        </p>
        <!-- <p>4、负责区域内市场的捕获、商机的管控、资源的协调与拉动；</p>
        <p>
          5、保持高度的市场敏锐度，根据市场信息、趋势和客户反馈，密切与技术部门协作。
        </p> -->
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>
          1、本科及以上学历（全日制），计算机、软件、通信、医疗等相关专业优先；
        </p>
        <p>2、年龄在25岁到35岁之间，心智成熟，行事稳重、灵活；</p>
        <p>3、良好的自驱和学习能力以及抗压能力，对成功充满渴望；</p>
        <p>4、具备在医疗软件、医药、器械、互联网医疗相关从业经验优先；</p>
        <p>5、良好的沟通、协调和表达能力；</p>
        <p>6、熟练操作日常办公软件，并具备良好的文案基础。</p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>1、薪资范围：10000-15000元/月；</p>
        <p>2、底薪：10000-10100元/月；</p>
        <p>3、社保类型：五险一金；</p>
        <p>4、提成方式：按回款提成；</p>
        <p>5、奖金补贴：餐补·交通补助·话补·年终奖。</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;
  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}

.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
