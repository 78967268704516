<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：软件实施工程师</p>
      <p class="part_title">一、岗位职责</p>
      <!-- <p class="title">在这里，你可以：</p> -->
      <!-- <div class="detail_desc">
        <p>1、利用具有竞争力的产品和商业模式为客户创造价值；</p>
        <p>2、利用公司搭建的平台积累优质人脉资源；</p>
        <p>3、在最具朝阳的科技医疗和科技养老的产业里施展你的才华；</p>
        <p>4、成为交易和谈判专家，沟通各种交易模式和商务方案，掌握谈判节奏。</p>
      </div>
      <p class="title">主要负责：</p> -->
      <div class="detail_desc">
        <p>
          1、从事医院软件项目实施工作，汇总客户需求，参与客户日常沟通以及满意度管理，把握客户需求动态以及新机会挖掘，统筹系统测试、上线等工作，对医院医生护士进行操作培训，并及时解决实施过程中出现的问题；
        </p>
        <p>
          2、根据项目要求按计划推进实施进度，协助进行项目生命周期管理，跟进项目进度，反馈项目风险；
        </p>
        <p>3、完成项目实施过程中相关过程文档的收集、整理和撰写。</p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>1、计算机或信息管理相关专业，本科及以上学历；</p>
        <p>
          2、具备3年以上软件实施、技术支持、项目助理类工作经验，熟悉医院业务，熟悉医院信息系统(HIS、EMR、
          LIS、PACS等）者优先；
        </p>
        <p>
          3、熟悉医院业务流程，对用户需求有深入理解，快速解决问题；
        </p>
        <p>
          4、具备扎实的文档能力，负责项目过程文档管理和实施计划编写等文档工作；
        </p>
        <p>5、至少熟悉一种关系型数据库 (SQL Server、Oracle)；</p>
        <p>6、性格外向，具备良好的表达能力和协调能力，团队意识强；</p>
        <p>7、严谨、务实、抗压，善于解决问题，具有良好的学习和自驱力；</p>
        <p>8、具备PMP证书者优先。</p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>
          1、我们提供有竞争力的薪资福利、提成方式和良好的发展平台，每个人都能获得非常好的发展；
        </p>
        <p>
          2、我们提供一流的工作氛围，与行业大佬携手同行，与优秀的团队一起成长，视野和格局能快速获得提升；
        </p>
        <p>
          3、我们期待您更优秀的同时，我们具有较强的培养员工的意愿，会为即将踏入社会大学的您量身定制大量培养计划。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;

  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}
/* @media screen and (max-width:1910px) {
    .box{
        padding: 0;
    }
} */
.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
