<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：资深JAVA开发工程师</p>
      <p class="part_title">一、岗位职责</p>
      <!-- <p class="title">在这里，你可以：</p>
      <div class="detail_desc">
        <p>1、利用具有竞争力的产品和商业模式为客户创造价值；</p>
        <p>2、利用公司搭建的平台实现人脉资源的价值最大化；</p>
        <p>
          3、在最具朝阳的健康、医疗科技产业里，事业和生意圈得到极大的拓展和释放。
        </p>
      </div>
      <p class="title">主要负责：</p> -->
      <div class="detail_desc">
        <p>1、JAVA技术方向的产品研发工作；</p>
        <p>2、参与项目业务需求讨论与功能实现方案，能给予新人进行指导；</p>
        <p>3、按既定方案进行程序的开发工作；</p>
        <p>4、负责编制与项目相关的需求、技术文档；</p>
        <p>5、参与项目代码、数据库设计、开发、测试等过程；</p>
        <p>6、负责项目数据库表设计、以及业务逻辑梳理。</p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>
          1、5年以上JAVA开发经验，基本功扎实，有良好的面向对象设计思想，熟悉常用设计模式，拥有良好的编程习惯；
        </p>
        <p>2、思路清晰，具有良好的沟通、表达能力，具有带领开发团队经验优先；</p>
        <p>
          3、熟练使用主流框架：Spring、SpringMVC、SpringBoot、springcloud等；
        </p>
        <p>4、熟练使用开发工具：Maven、Git等；</p>
        <p>
          5、熟练掌握关系型数据库MySQL或Oracle，能根据业务场景写出多表关联的SQL语句；
        </p>
        <p>6、计算机或信息管理相关专业，本科及以上学历；</p>
        <p>7、严谨、务实、抗压，善于解决问题，具有良好的学习和自驱力。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;
  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}

.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
