<template>
  <div>
    <div class="item_ flex j_c_b" v-for="(item, index) in list" :key="index">
      <div class="flex_1">
        <div class="title flex a_i_c">
          <span></span>
          {{ item.title }}
        </div>
        <ul>
          <li
            class="item_text_list"
            v-for="(item_, index_) in item.list"
            :key="index_"
            :style="{
              marginTop: index_ == 1 && item.list.length < 3 ? '20px' : '',
            }"
          >
            {{ item.list.length > 2 ? index_ + 1 + "." : "" }}{{ item_ }}
          </li>
        </ul>
      </div>
      <img class="img flex_none" :src="item.img" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "薪酬福利",
          img: require("@/assets/recruit/01.png"),
          list: [
            "我们每年都将审视同行业薪酬市场，以确保公司员工的薪酬在同行业内具备良好的竞争力",
            "我们以业绩为导向，为不同的员工制定完善的绩效奖金计划或业务提成计划",
            "通过共同创业者计划晋升为事业合伙人可以参与到公司的股权或期权分配",
            "我们每月会为员工提供各项补贴（包含但不限于餐补、交通、通话补助等）",
            "我们为每位员工缴纳五险一金",
            "我们有较强的培养员工的意愿，为员工量身定制大量培养计划",
            "我们提供较好的工作环境，并勇于给新人机会，使每个人都能获得快速发展",
            "我们为每一位入职员工配备超薄笔记本电脑",
            "我们定期组织员工体育和文化活动",
            "我们为员工提供下午茶和点心",
            "我们会在节日为每一位员工发放礼品",
            "我们会为每一位员工庆生",
            "我们设置了“公司图书馆”，为员工精心挑选有价值书籍",
            "我们会每年出资组织全体员工健康体检",
            "我们会在年度总结会议或年会期间组织旅游活动",
          ],
        },
        {
          title: "我们致力于打造优秀的团队",
          img: require("@/assets/recruit/02.png"),
          list: [
            // "每个人都是自己位置的专家，不论营销还是技术，都业务娴熟",
            // "团队成员作战配合默契，一人组织战斗，全队都在积极动态的配合",
            // "牢牢控制份内工作的节奏，张弛有度，时机一到突然发力达成目标",
            // "不论公司内外，均拥有一群粉丝和协作者，让自己有资源做事",
            // "长期保持团队的优秀水准，坚持末位淘汰制度",
            "选对赛道、乘势而上、领跑同龄、共创卓越！",
            "我们精挑细选每一位成员，让你与一群超棒的同事共事！",
            "与行业精英携手同行，快速提升视野！",
            "我们崇尚“职”言不讳，从来不空谈阔论！",
            "我们期待你对公司忠诚，也会让你的潜力加速成为实力！",
            "我们追求高绩效和卓越德管理文化，对创新有着极致的追求！",
            "我们彼此帮助，共同成就！",
          ],
        },
        {
          title: "我们推崇的工作方法和氛围",
          img: require("@/assets/recruit/isimg.png"),
          list: [
            // "事上练、数上见",
            // "确保效率，兼顾高质量，工欲善其事必先利其器",
            // "只有自律才能配的上自由",
            // "对企业忠诚、负责。",
            // "招聘并培养5年后能成为你老板的人，多去成就他人，领导者、导师不以“唯上”、“听话”去驾驭别人，鼓励以公司利益为基础的百舸争流和协作",
            // "期待员工都能拥有创业心态，自我驱动，用心去做事，赋予工作以思想和灵魂；不要让工作的闭环耽误在自己手上超过10分钟，重要事项写备忘录发送主管或干系人知晓",
            // "敬畏每一分钱",
            // "汇报用数字和简明扼要的文字呈现，“数上见”要有环比和同比",
            // "不给自己的成长和工作设限，勇于设置比现有跨栏能力高三成以上的高度，有勇气和魄力接受挑战，允许犯错",
            // "有前瞻意识的同时，用良好的视野和认知去拥抱变化，拥抱不确定性",
            "务实平等的工作氛围",
            "专注完成业务目标，没有领地意识",
            "高效开放的办公环境，开放式工位，即时讨论，让灵感碰撞不受空间约束",
            "追求极致，务实敢为，开放谦逊，坦诚清晰，多元兼容，共同创业",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.flex_1 {
  flex: 1;
}
.flex_none {
  flex: none;
}
.img {
  width: 46%;
  /* height: 300px; */
  object-fit: cover;
  flex: none;
}
.item_:nth-child(1) .img {
  height: 600px;
  margin-top: 70px;
}
li {
  color: #61646b;
  /* font-size: 18px; */
  /* line-height: 36px; */
  list-style: none;
  width: 90%;
}
.title {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  /* font-size: 36px;
    line-height: 50px; */
  margin-bottom: 0.32rem;
  margin-top: 10px;
  font-size: 0.36rem;
  line-height: 0.5rem;
}
.title span {
  width: 0.03rem;
  height: 0.38rem;
  left: 190px;
  top: 126px;
  background: #1aa858;
  margin-right: 6px;
}
.item_:nth-child(2n) {
  background: #f7f7fa;
  flex-direction: row-reverse;
}
.item_:nth-child(2n) .flex_1 {
  padding-left: 160px;
}
.item_ {
  padding: 1.2rem 1.9rem;
}
.item_:nth-child(1) {
  /* padding-top: 40px; */
}
li {
  font-size: 0.18rem;
  line-height: 0.36rem;
}
.item_:nth-child(1) .img {
  height: 5.3rem;
  /* margin-top: 1rem; */
}
@media screen and (max-width: 1024px) {
  .item_ {
    padding: 0.8rem 20px;
    flex-wrap: wrap;
  }
  .item_ img {
    width: 100%;
    margin-top: 20px !important;
  }
  .item_ .flex_1 {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }
  li {
    line-height: 0.5rem;
  }
}

/* @media screen and (max-width:1910px) {
    .item_:nth-child(2n) .flex_1{
        padding-left:10%;
    }
    
    .title{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .title span{
        width: 3px;
        height: 24px;
        left: 190px;
        top: 126px;
        background: #1AA858;
        margin-right: 6px;
    }
    li{
        color: #61646B;
        font-size: 16px;
        line-height: 32px;
        list-style: none;
    }
} */
</style>
