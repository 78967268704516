<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：销售总监</p>
      <p class="part_title">一、岗位职责</p>
      <p class="title">在这里，你可以：</p>
      <div class="detail_desc">
        <p>1、利用具有竞争力的产品和商业模式为客户创造价值；</p>
        <p>2、利用公司搭建的平台实现人脉资源的价值最大化；</p>
      </div>
      <p class="title">主要负责：</p>
      <div class="detail_desc">
        <p>1、扩建团队，带领团队不断成长；</p>
        <p>2、带领团队完成既定的营销指标；</p>
        <p>3、负责关键客户的关系拓展，支撑业绩完成；</p>
        <p>4、负责销售商机的管理和推动、资源的协调与业绩目标的拉动；</p>
        <p>5、负责生态伙伴关系的维护。</p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>
          1、本科及以上学历（全日制），计算机、软件、通信、医疗等相关专业优先；
        </p>
        <p>2、年龄在28岁到35岁之间，心智成熟，行事稳重、灵活；</p>
        <p>3、良好的自驱和学习能力以及抗压能力，对成功充满渴望；</p>
        <p>4、具备在医疗软件、医药、器械、互联网医疗相关从业经验优先；</p>
        <p>5、良好的沟通、协调和表达能力；</p>
        <p>6、熟练操作日常办公软件，并具备良好的文案基础。</p>
        <!-- <p>
          7、熟练操作日常办公软件、飞书等高效协作平台，并具备良好的文案基础。
        </p> -->
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>
          1、我们提供有竞争力的薪资福利、提成方式和良好的发展平台，每个人都能获得非常好的发展；
        </p>
        <p>2、我们在寻找有潜力成为合伙人的销售精英，我们期待您很优秀。</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.25rem;
  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}
/* @media screen and (max-width:1910px) {
    .box{
        padding: 0;
    }
} */
.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 0.2rem 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.detail_desc p {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc p {
    line-height: 0.5rem;
  }
}
</style>
