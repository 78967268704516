<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：医学知识库专家(兼职)</p>
      <p class="part_title">一、岗位职责</p>
      <!-- <p class="title">在这里，你可以：</p> -->
      <div class="detail_desc">
        <p>1、收集整理围绕疾病管理领域的文献，协助产品在治疗领域的价值创造；</p>
        <p>
          2、协助产品不断迭代，提供医学、治疗意见，不断优化产品的临床业务场景和方案；
        </p>
        <p>3、对接临床专家，整理专家意见，维护医学知识库；</p>
        <p>
          4、设计或发起相关临床研究项目，并收集整理数据，协助产品循证医学证据。
        </p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>1、临床、药学、内科等医学本科以上毕业，硕士研究生优先；</p>
        <p>2、具备3年以上临床工作经验，有较强的研究精神优先；</p>
        <p>
          3、有较强沟通表达能力, 协作精神，能承担一定的压力，能积极应对新挑战。
        </p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
         <p>
          1、我们提供有竞争力的薪资福利、提成方式和良好的发展平台，每个人都能获得非常好的发展；
        </p>
        <p>
          2、我们提供一流的工作氛围，与行业大佬携手同行，与优秀的团队一起打拼；
        </p>
        <p>
          3、我们期待您更优秀的同时，我们具有较强的培养员工的意愿，会为具备三年以上工作经验的您量身定制大量培养计划。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;

  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}
/* @media screen and (max-width:1910px) {
    .box{
        padding: 0;
    }
} */
.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
