<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：前端开发工程师</p>
      <p class="part_title">一、岗位职责</p>
      <p class="title">在这里，你可以：</p>
      <div class="detail_desc">
        <p>
          在这里，你将从事IT应用层软件、互联网医疗软件、医疗人工智能等的设计开发，可以接触到当下最前沿的产品和软件技术，最好的健康产业客户和商业模式，成为行业专家；
          工作包括但不限于：
        </p>
        <p>
          （1）完成从客户需求到软件产品定义、架构设计、开发实现、再到上线运营维护等产品生命周期中的各个环节；
        </p>
        <p>
          （2）创造性解决产品在实现过程中的技术难题，应用前沿技术提升产品的核心竞争力；
        </p>
        <p>
          （3）有机会参与业界前沿技术研究和规划，与国内知名专家一起工作、交流，提升自己的影响力。
        </p>
      </div>
      <p class="title">二、岗位要求</p>
      <div class="detail_desc">
        <p>1、计算机、软件、通信等相关专业本科及以上学历（全日制）；</p>
        <p>
          2、根据UI设计产出，完成前端静态资源（html、css、javascript等）的制作；
        </p>
        <p>
          3、与UI、后端工程师协作，负责产品前端（PC、H5、微信H5、微信小程序等）页面的开发和维护工作；
        </p>
        <p>
          4、实现并维护基于产品线或风格主题的样式标准，形成可有效复用的样式库；
        </p>
        <p>
          5、持续优化前端应用，改善用户交互以及视觉，保证前端网页的兼容性以及页面响应速度并负责前端代码的维护；
        </p>
        <p>6、负责前端框架设计、前端开发规范整理与实施，前端文档的编写。；</p>
        <p>
          7、热爱编程，热爱学习，善于总结分享；具有较强的工作责任心，并善于沟通，乐于团队协作；
        </p>
        <p>8、有互联网医疗软件、健康管理软件以及人工智能开发经验优先。</p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>我们注重每一位员工的成长</p>
        <p>1、我们期望你更优秀的同时，我们为你提供更具竞争力的薪资待遇；</p>
        <p>
          2、我们为你提供一流的工作氛围与大量的培训机会，与行业大咖携手同行，与优秀的团队一起工作，视野与经验均能快速提升和成长。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;
  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}
/* @media screen and (max-width:1910px) {
    .box{
        padding: 0;
    }
} */
.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>