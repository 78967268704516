<template>
  <div v-runshow>
    <div class="flex flex_d j_c_c a_i_c" style="margin-top: 30px">
      <div class="top_title" style="">招贤纳士</div>
      <div class="tips"></div>
    </div>
    <div class="box">
      <div
        @click="choose(item, index)"
        :class="{ active: item.com == current }"
        class="item"
        v-for="(item, index) in list"
        :key="index"
      >
        <div>
          {{ item.name }}
        </div>
      </div>
    </div>
    <div style="padding: 30px 0 30px">
      <component :is="current"></component>
    </div>
    <div class="tel">联系人：张女士 15150575101</div>
    <div class="tel" style="padding-bottom: 0.5rem">
      邮&nbsp;&nbsp;&nbsp;箱：zhangrui-one@outlook.com
    </div>
  </div>
</template>

<script>
import one from "./one.vue";
import two from "./two.vue";
import three from "./three.vue";
import four from "./four.vue";
import five from "./five.vue";
import six from "./six.vue";
import seven from "./seven.vue";
import eight from "./eight.vue";
import nine from "./nine.vue";
import ten from "./ten.vue";
import eleven from "./eleven.vue";
import twelve from "./twelve.vue";
import thirteen from "./thirteen.vue";

export default {
  components: {
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
  },
  data() {
    return {
      current: "one",
      list: [
        {
          name: "销售总监",
          com: "one",
        },
        // {
        //   name: "运营总监",
        //   com: "ten",
        // },
        {
          name: "健康管理师",
          com: "twelve",
        },
        // {
        //   name: "财务经理",
        //   com: "nine",
        // },
        {
          name: "销售经理",
          com: "two",
        },
        {
          name: "产品经理",
          com: "thirteen",
        },

        // {
        //   name: "JAVA开发工程师",
        //   com: "three",
        // },
        // {
        //   name: "前端开发工程师",
        //   com: "four",
        // },
        // {
        //   name: "实习生、应届毕业生（销售岗）",
        //   com: "five",
        // },

        {
          name: "软件实施工程师",
          com: "eight",
        },
        {
          name: "资深Java",
          com: "eleven",
        },
        { name: "医学知识库专家", com: "six" },
        { name: "医学知识库专家(兼职)", com: "seven" },
      ],
    };
  },
  methods: {
    choose(item, index) {
      this.current = item.com;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.tel {
  font-size: 0.18rem;
  line-height: 0.25rem;
  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem 0.1rem 1.9rem;
  font-weight: 600;
}
.top_title {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 53px;

  color: #21252e;
  margin-top: 40px;
}
.item {
  padding: 25px 50px;
  font-size: 14px;
  cursor: pointer;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  color: #21252e;

  font-size: 0.18rem;
  line-height: 0.25rem;
}
.active {
  background: #fff;
  color: #222;
  position: relative;
}
.active::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #1aa858;
  position: absolute;
  left: 0;
  bottom: 0;
}
.box {
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100vw;
}
.tips {
  width: 50px;
  height: 3px;
  background: #1aa858;

  width: 74px;
  height: 3px;
  left: 923px;
  top: 198px;

  background: #1aa858;
  margin-top: 20px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1024px) {
  .tel {
    padding: 0 20px;
    line-height: 0.4rem;
  }
  .box {
    width: 100vw;
    /* margin-left: -20px; */
    white-space: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }
  .box > div {
    flex: none;
    font-size: 0.14rem;
    padding: 0.2rem 0.3rem;
  }
  .active {
    color: #1aa858;
    background: transparent;
  }
  .top_title {
    font-size: 18px;
    padding-bottom: 0;
    line-height: 20px;
    margin-top: 10px;
  }
  .tips {
    margin-bottom: 10px;
  }
}
</style>
