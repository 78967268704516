<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：健康管理师</p>
      <p class="part_title">一、岗位职责</p>
      <!-- <p class="title">在这里，你可以：</p>
      <div class="detail_desc">
      
      </div> -->
      <p class="title">主要负责：</p>
      <div class="detail_desc">
        <p>
          1、对医院科室目标客户进行健康宣教和相关服务告知，签署服务知情同意书；
        </p>
        <p>2、收集、管理客户及患者的健康及疾病信息，确保平台上各项信息准确；</p>
        <p>3、在客户与医生之间进行交流，校准服务各个环节的信息；</p>
        <p>4、落实执行各项疾病管理的制度，规范工作流程；</p>
        <p>5、聚焦提升患者服务体验，配合健康管理中心优化业务流程；</p>
        <p>6、负责中心安排的其他任务。</p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>1、大专及以上学历（全日制），护理、临床等相关专业；</p>
        <p>2、有耐心、有较强的服务意识；</p>
        <p>3、有健康管理师、护士证、营养师等资格证书者优先；</p>
        <p>4、熟练操作日常办公软件。</p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>1、薪资范围：10000-15000元/月；</p>
        <p>2、底薪：10000-10100元/月；</p>
        <p>3、社保类型：五险一金；</p>
        <p>4、提成方式：按回款提成；</p>
        <p>5、奖金补贴：餐补·交通补助·话补·年终奖。</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;
  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}

.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
