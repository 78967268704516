<template>
  <div>
        <isHeader ref="isHeader" />
        <div ref="scrollBox" style="height:100vh;overflow-y:auto;overflow-x:hidden;">
            <commonTop :info="{
                title:'招贤纳士',
                desc:'我们求贤若渴，期待您的加入！',
                show_desc:true
            }" :img="require('@/assets/recruit/banner.png')">
                <!-- 插槽 -->
                <div>
                    <!-- 让生命信息智慧联结 -->
                </div>
            </commonTop>
            <!--  -->
            <isCom>
                
            </isCom>
            <isFooter/>
        </div>
  </div>
</template>

<script>
import isHeader from '@/components/header/index.vue';
import isFooter from '@/components/footer/index.vue';
import commonTop from '@/components/commonTop/index.vue';
import isCom from '@/components/recruit/com.vue'
export default {
    name:'EcologicalRecruitment',
    components:{
        isCom,
        commonTop,
        isHeader,
        isFooter,
    },
    data(){
        return {
            info:{
                partOne:false,
                partTwo:{
                    title:'招募方向',
                    sub:'生态伙伴的方向',
                    list:[
                        {
                            title:'在健康管理领域，业务关联性较高的专业类软、硬件产品厂商',
                            desc:'合作方式：与华信互联在健康管理领域开展产品生态和营销渠道层面的合作',
                        },
                    ],
                    bg:require('@/assets/EcologicalRecruitment/bg.png'),
                    right_img:require('@/assets/EcologicalRecruitment/swiper_right_img.png'),
                }
            }
        }
    },
    mounted(){
        let that = this;
        this.$refs.scrollBox.addEventListener("scroll",function(e){
            if(e.target.scrollTop>80){
            that.$refs.isHeader.setScrollBg(true);
            }else{
            that.$refs.isHeader.setScrollBg(false);
            }
        })
    },
}
</script>

<style>
@media screen and (max-width:1024px) {
}
</style>