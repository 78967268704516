<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：医学知识库专家</p>
      <p class="part_title">一、岗位职责</p>
      <!-- <p class="title">在这里，你可以：</p> -->
      <div class="detail_desc">
        <p>
          1、支持医学知识库的研发工作，包括诊后疾病管理相关的随访模板健康宣教材料和健康评估量表的构筑，为产品迭代提供医学专业视角；
        </p>
        <p>
          2、对接医学顾问和专家，整理他们的意见，不断积累平台医学知识库以及相关内容的生产；
        </p>
        <p>
          3、结合自身行业经验，准确把握、挖掘、分析用户在健康管理领域的业务需求，与研发团队紧密配合，为产品迭代提出建议；
        </p>
        <p>
          4、临床级功能宣传物料把关，为产品涉及医疗术语的内容提供意见和建议；
        </p>
        <p>
          5、挖掘疾病、慢病治疗过程的知识数据并确保其科学性，熟悉各项健康管理数据指标，搭建用户运营数据体系，分析运营效果，优化运营策略，提升平台用户体验，促进平台用户数据增长；
        </p>
        <p>
          6、参与产品部署后的使用效果调研，参与系统的实施和资料整理，对系统用户进行专业指导；
        </p>
        <p>
          7、参与政府相关管理机构的标准制定与起草，与合作单位联合出品慢病管理相关的期刊等出版物；
        </p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>1、临床、药学、内科等医学本科以上毕业，硕士研究生优先；</p>
        <p>2、具备3年以上临床工作经验，有较强的研究精神优先；</p>
        <p>
          3、有较强沟通表达能力, 协作精神，能承担一定的压力，能积极应对新挑战。
        </p>
        <p>
          4、有优秀的文字功底和沟通能力。能够将严肃、严谨的医学知识深入浅出地进行阐述。有经营公众号或视频自媒体经历者优先；
        </p>
        <p>
          5、结果导向，善用团队内外部资源，团队协作提升业务目标，有医带患成功经验优先。
        </p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>
          1、我们提供有竞争力的薪资福利、提成方式和良好的发展平台，每个人都能获得非常好的发展；
        </p>
        <p>
          2、我们提供一流的工作氛围，与行业大佬携手同行，与优秀的团队一起打拼；
        </p>
        <p>
          3、我们期待您更优秀的同时，我们具有较强的培养员工的意愿，会为具备三年以上工作经验的您量身定制大量培养计划。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;

  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}
/* @media screen and (max-width:1910px) {
    .box{
        padding: 0;
    }
} */
.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
