<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：财务经理</p>
      <p class="part_title">一、岗位职责</p>
      <!-- <p class="title">在这里，你可以：</p> -->
      <div class="detail_desc">
        <p>1、全面负责公司日常会计及税务工作；</p>
        <p>2、建立和完善公司内部财务管理制度及会计核算体系；</p>
        <p>3、建立和完善公司税务体系和流程制度；</p>
        <p>4、做好公司财务报表、财务数据的审理工作；</p>
        <p>5、积极履行公司授予的其他职权工作。</p>
      </div>
      <!-- <p class="title">主要负责：</p>
      <div class="detail_desc">
        <p>
          1、接受公司大量的培训，熟练掌握公司产品属性，为医疗行业客户提供高质量的解决方案；
        </p>
        <p>2、负责公司领导与合伙人的资源对接和关系拓展；</p>
        <p>3、负责区域内市场信息的捕获；</p>
        <p>
          4、保持高度的市场敏锐度，根据市场信息、趋势和客户反馈，密切与技术部门协作。
        </p>
      </div> -->
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>1、积极乐观向上、心智成熟、态度端正；</p>
        <p>2、愿意持续学习与成长；</p>
        <p>3、本科相关学历，中级会计师以上职称；</p>
        <p>4、具有全面的财务专业知识，以及总账会计和财务管理基础；</p>
        <p>5、有3–5年以上相关财务工作经验，具有高企申办经验的优先；</p>
        <p>6、有良好的做事态度和事业心，愿意与企业同舟共济。</p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>
          1、我们提供有竞争力的薪资福利、提成方式和良好的发展平台，每个人都能获得非常好的发展；
        </p>
        <p>
          2、我们提供一流的工作氛围，与行业大佬携手同行，与优秀的团队一起成长，视野和格局能快速获得提升；
        </p>
        <p>
          3、我们期待您更优秀的同时，我们具有较强的培养员工的意愿，会为即将踏入社会大学的您量身定制大量培养计划。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;

  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}
/* @media screen and (max-width:1910px) {
    .box{
        padding: 0;
    }
} */
.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
