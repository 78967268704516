<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：实习生、应届毕业生（销售岗）</p>
      <p class="part_title">一、岗位职责</p>
      <p class="title">在这里，你可以：</p>
      <div class="detail_desc">
        <p>1、利用具有竞争力的产品和商业模式为客户创造价值；</p>
        <p>2、利用公司搭建的平台积累优质人脉资源；</p>
        <p>3、在最具朝阳的科技医疗和科技养老的产业里施展你的才华；</p>
        <p>4、成为交易和谈判专家，沟通各种交易模式和商务方案，掌握谈判节奏。</p>
      </div>
      <p class="title">主要负责：</p>
      <div class="detail_desc">
        <p>
          1、接受公司大量的培训，熟练掌握公司产品属性，为医疗行业客户提供高质量的解决方案；
        </p>
        <p>2、负责公司领导与合伙人的资源对接和关系拓展；</p>
        <p>3、负责区域内市场信息的捕获；</p>
        <p>
          4、保持高度的市场敏锐度，根据市场信息、趋势和客户反馈，密切与技术部门协作。
        </p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>1、本科学历（全日制），计算机、软件、通信、医疗等相关专业优先；</p>
        <p>2、心智成熟，行事稳重、灵活；</p>
        <p>
          3、良好的自驱力、学习能力以及抗压能力，用心做事，对事业和赚钱充满渴望；
        </p>
        <p>4、良好的沟通和表达能力；</p>
        <p>
          5、熟练操作日常办公软件、飞书等高效协作平台，具备良好的文案基础尚佳。
        </p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>
          1、我们提供有竞争力的薪资福利、提成方式和良好的发展平台，每个人都能获得非常好的发展；
        </p>
        <p>
          2、我们提供一流的工作氛围，与行业大佬携手同行，与优秀的团队一起成长，视野和格局能快速获得提升；
        </p>
        <p>
          3、我们期待您更优秀的同时，我们具有较强的培养员工的意愿，会为即将踏入社会大学的您量身定制大量培养计划。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;

  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}
/* @media screen and (max-width:1910px) {
    .box{
        padding: 0;
    }
} */
.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;

  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>