<template>
  <div>
    <div class="box">
      <p class="title">招聘岗位：产品经理</p>
      <p class="part_title">一、岗位职责</p>
      <!-- <p class="title">在这里，你可以：</p>
      <div class="detail_desc">
      
      </div> -->
      <p class="title">主要负责：</p>
      <div class="detail_desc">
        <p>
          1、需求分析：理解客户需求，进行原型设计及产品设计，编写产品设计文档；
        </p>
        <p>
          2、产品规划：熟悉医疗、大健康行业，不断提升公司相关产品矩阵的竞争能力；
        </p>
        <p>3、产品迭代：与研发、实施团队通力合作，不断迭代产品功能；</p>
        <p>4、竞品分析：对医疗行业内竞争产品进行分析、比较，并持续推动创新；</p>
        <p>5、文档编辑：编写软件需求说明文档、功能设计文档、系统建设方案等；</p>
        <p>6、市场支持：配合营销，对用户进行产品宣传、演示以及其他支持。</p>
      </div>
      <p class="part_title">二、岗位要求</p>
      <div class="detail_desc">
        <p>
          1、统招本科及以上学历、硕士优先，3年以上医疗软件产品经理经验，至少1款软件产品上线经历；
        </p>
        <p>
          2、熟悉医疗大健康业务模式，对健康管理、互联网医疗行业有深刻的认知；
        </p>
        <p>
          3、具备出色的逻辑思考能力和文字功底，能够撰写需求文档和产品原型，对产品有良好的规划能力；
        </p>
        <p>4、具备非常好的沟通表达能力，以及宣讲能力；</p>
        <p>5、能适应短期出差，具备良好的职业素养；</p>

        <p>6、严谨、务实、抗压，善于解决问题，具有良好的学习和自驱力。</p>
      </div>
      <!-- <p class="part_title">三、薪酬待遇</p>
      <div class="detail_desc">
        <p>1、薪资范围：10000-15000元/月；</p>
        <p>2、底薪：10000-10100元/月；</p>
        <p>3、社保类型：五险一金；</p>
        <p>4、提成方式：按回款提成；</p>
        <p>5、奖金补贴：餐补·交通补助·话补·年终奖。</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  font-size: 0.18rem;
  line-height: 0.36rem;
  background: #fff;
  color: #21252e;
  padding: 0;
  font-weight: 400;
  color: #21252e;
  font-family: "PingFang SC";
  font-style: normal;
  box-sizing: border-box;
  padding: 0 1.9rem;
}

.title {
  font-weight: 800;
  color: #000;
  margin: 0.1rem 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.part_title {
  background: #f7f7f7;
  color: #000;
  font-weight: 800;
  padding: 4px 6px;
  box-sizing: border-box;
  margin: 20px 0;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 0.18rem;
  line-height: 0.36rem;
  color: #21252e;
}
.detail_desc {
  line-height: 0.36rem;
}
@media screen and (max-width: 1024px) {
  .box {
    padding: 0 20px;
  }
  .detail_desc {
    line-height: 0.5rem;
  }
}
</style>
