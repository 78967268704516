<template>
  <div>
    <isTab />
    <div class="box">
      <!--  -->

      <isPlan />
    </div>
    <partList />
    <!--  -->
  </div>
</template>

<script>
import isTab from "./tab/index.vue";
import isPlan from "./plan.vue";
import partList from "./partList.vue";
export default {
  components: {
    isTab,
    isPlan,
    partList,
  },
  data() {
    return {};
  },
  mounted() {
    var mySwiper = new Swiper(".is_mobile_swiper", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      // 如果需要分页器
      pagination: {
        el: ".is_mobile_swiper_agination",
      },
    });
  },
};
</script>

<style scoped>
.mobile_ {
  height: 0;
  overflow: hidden;
}
.box {
  padding: 0 1.9rem;
  box-sizing: border-box;
}
.pc_ {
  display: flex;
  justify-content: center;
}
.item_ {
  width: 20vw;
  height: 20vw;
  max-width: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 800;
  border: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: none;
}
.item_:nth-child(1) {
  border-left: 1px solid #ccc;
}
.item_ svg {
  margin-bottom: 20px;
}
.tips {
  width: 80px;
  height: 3px;
  background: #1aa858;
  margin: 10px;
}
.partTwo {
  width: 100vw;
  margin-left: -80px;
  background-color: #333;
  min-height: 300px;
  color: #fff;
  font-size: 14px;
  line-height: 180%;
  padding: 30px 20px;
  box-sizing: border-box;
  background-image: url("../../assets/aboutUs/bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: center;
}
.flex_1 {
  flex: 1;
}
textarea,
input {
  background: rgba(0, 0, 0, 0);
  flex: 1;
  outline: none;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}
.item_row {
  align-items: flex-start;
  padding: 0 10px;
  margin-bottom: 20px;
  background: #f7f7fa;
  border-radius: 6px;
  display: flex;
  width: 100%;
}
.item_row > span {
  color: red;
  margin-top: 8px;
  font-size: 14px;
}
/*  */
.top_com .tips {
  display: none;
}
.clear {
  clear: both;
}

.tips {
  width: 70px;
  height: 3px;
  background: #1aa858;
}
.is_4_com_swiper-pagination >>> .swiper-pagination-bullet {
  background: #1aa858;
}
@media screen and (max-width: 1024px) {
  .mobile_ {
    height: auto;
    overflow: visible;
  }
  .mobile_ .item_ {
    width: 48vw;
    height: 48vw;
  }
  .pc_ {
    display: none !important;
  }
  .partTwo {
    margin-left: -20px;
  }
  .lastcom .right_ctx_box {
    width: 100%;
    padding: 0;
  }
  .lastcom > div:nth-child(1) {
    width: calc(100vw - 60px);
    flex: none;
  }
  .right_ctx {
    width: 100%;
    margin: 20px 0 !important;
    padding: 20px;
  }
  .top_com {
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* flex-direction: column-reverse; */
  }
  .new_title {
    text-align: center;
    /* width: 100%; */
    box-sizing: border-box;
    justify-content: center;
  }
  .new_title span {
    display: none;
  }
  .top_com .is_title span {
    display: none !important;
  }
  .top_com .is_title {
    margin: 20px 0;
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    padding: 0 40px;
  }
  .top_com .tips {
    display: inline-block;
    margin-bottom: 20px;
  }
  .top_com > div {
    width: 100% !important;
    padding-right: 0 !important;
    margin-bottom: 10px;
  }
  .top_com > img {
    width: 100% !important;
  }
  .box {
    padding: 20px;
  }
}
</style>